





















































































import { stateModule } from '@/store'
import { Vue, Component } from 'vue-property-decorator'

import Modal from '@/components/modals/Default.vue'
import Rating from '@/components/inputs/Rating.vue'
import TextareaInput from '@/components/inputs/Textarea.vue'
import FilesUpload from '@/components/inputs/FilesUpload.vue'
import SmallLoader from '@/components/loaders/SmallLoader.vue'

import {FeedbackRequest} from '@/requests/misc/FeedbackRequest'
import {GlobalService} from '@/services/global'

const service: GlobalService = new GlobalService()

@Component({
  components: {
    Modal,
    Rating,
    SmallLoader,
    FilesUpload,
    TextareaInput
  }
})
export default class FeedbackForm extends Vue {

  private get feedbackFormOpen(): boolean | null {
    return stateModule.feedbackFormOpen
  }

  private form: FeedbackRequest = new FeedbackRequest()
  private isSubmitting: boolean = false
  private submitted: boolean = false

  private get title(): string {
    if (this.submitted) {
      return 'Thank you for sharing'
    } else {
      switch (this.form.media_type) {
        case 'rating':
          return 'Let us know what you think'
        case 'support':
          return 'Get in touch with our support team'
        case 'problem':
          return 'Report a problem'
        default:
          return 'Let us know what you think'
      }
    }
  }

  private get successMessage(): string {
    switch (this.form.media_type) {
      default:
        return 'Your message will be picked up by our support team as soon as possible.'
    }
  }

  private closeModal(): void {
    stateModule.setFeedbackForm(false)
    // Wait for the animation to complete before resetting form
    setTimeout(() => {
      this.submitted = false
      this.form = new FeedbackRequest()
    }, 300)
  }

  private async submitRating(): Promise<void> {
    this.isSubmitting = true
    try {
      await service.feedback(this.form)
      this.submitted = true
    } catch (e) {
      console.error(e.message)
    } finally {
      this.isSubmitting = false
    }
  }

  private goBack(): void {
    this.form = new FeedbackRequest()
  }

}
