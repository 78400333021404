




























import { Vue, Component, Prop, Model } from 'vue-property-decorator'

@Component
export default class Rating extends Vue {

  @Model('input', { type: Number })
  private readonly value!: number

  @Prop({ default: false })
  private readonly disabled!: boolean

  @Prop()
  private readonly name!: string

  @Prop()
  private readonly label!: string

  private onHoverIndex: number | null = null

  private onMouseEnter(index: number) {
    this.onHoverIndex = index
  }

  private onMouseLeave() {
    this.onHoverIndex = null
  }

  private starIsFilled(index: number): boolean {
    return this.onHoverIndex ? index <= this.onHoverIndex : index <= this.value
  }

  private setRating(index: number) {
    this.$emit('input', index)
  }

}
