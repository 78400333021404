























// Components
import { stateModule } from '@/store'
import { Component, Vue, Watch, Prop } from 'vue-property-decorator'

import Notifications from '@/components/list/Notifications.vue'

import { NotificationsController } from '@/controllers/notifications/NotificationsController'

@Component({
  components: {
    Notifications,
  },
})
export default class NotificationMenu extends Vue {

  @Prop()
  private readonly notifications!: NotificationsController

  @Prop()
  private readonly placeholder!: string

  // Watch
  @Watch('$route')
  private async onRouteChange(): Promise<void> {
    stateModule.setNotificationsOpen(false)
  }

  @Watch('open')
  private async onOpenClosed(val: boolean, oldVal: boolean): Promise<void> {
    if (!val && oldVal) {
      this.$emit('close')
      // Refresh notifications
      stateModule.setNotificationsOpen(false)
    }
  }

  private get open(): boolean | null {
    return stateModule.openNotifications
  }

  // methods
  private closeMenu(): boolean {
    return stateModule.setNotificationsOpen(false)
  }

}
