






























import { Component, Prop, Vue, Model } from 'vue-property-decorator'

@Component
export default class FileUpload extends Vue {

  @Prop()
  private readonly name!: string

  @Prop()
  private readonly label!: string

  @Prop({ default: 'Drag & Drop your file(s) here' })
  private readonly placeholder!: string

  @Prop({ default: 'image/png, image/jpeg, application/pdf' })
  private readonly accept!: string

  @Prop({ default: true })
  private readonly multiple!: File

  @Prop({ default: false })
  private readonly disabled!: boolean

  @Model('input')
  private readonly value!: File[] | File | undefined

  // file size is in MB
  @Prop({ default: 10  })
  private readonly maxFileSize!: number

  private isDraggingFilesOver: boolean = false

  private get maxFileSizeInBytes(): number {
    return this.maxFileSize * (1000 * 1000)
  }

  private get fileSizeUsed(): number {
    if (Array.isArray(this.value)) {
      return this.value.map((file) => file.size).reduce((accumulator, currentValue) => currentValue + accumulator, 0)
    } else {
      return (this.value?.size ?? 0)
    }
  }

  private get fileSizeUsedInMB(): string {
    return (this.fileSizeUsed / (1000 * 1000)).toFixed(2)
  }

  private get maxFileSizeExceeded(): boolean {
    return this.fileSizeUsed > this.maxFileSizeInBytes
  }

  private get hasFiles(): boolean {
    return !!this.files && this.files.length > 0
  }

  private get files(): File[] {
    if (this.value) {
      if (this.multiple) {
        return Array.isArray(this.value) ? this.value : [this.value]
      } else {
        return Array.isArray(this.value) ? [this.value[0]] : [this.value]
      }
    }
    return []
  }

  private openFilePicker(): void {
    if (this.disabled) return
    const FILE_INPUT = this.$refs.input as HTMLInputElement
    if (FILE_INPUT) FILE_INPUT.click()
  }

  private toggleDragover(dragging: boolean): void {
    if (this.disabled) return
    this.isDraggingFilesOver = dragging
  }

  private onChange(event: InputEvent): void {
    const target = event.target as HTMLInputElement
    const files = target.files
    if (!files) return

    this.handleFiles(files)
  }

  private onDropFiles(event: DragEvent): void {
    if (this.disabled) return
    this.toggleDragover(false)

        // Check if files are present
    const files = event?.dataTransfer?.files
    if (!files) return

    this.handleFiles(files)
  }

  private handleFiles(files: FileList): void {
    if (!files) return

    // Get all acccepted file types and convert to array of types
    const acceptedFileTypes = this.accept.split(', ')

    const draggedFiles: File[] = []
    for (const file of files) {

      // Check if file type is accepted in this input
      if (!acceptedFileTypes.includes(file.type)) {
        alert(`File type not supported`)
        console.error(file)
      } else {
        // Push file onto new draggedFiles array
        draggedFiles.push(file)
      }
    }

    if (this.multiple) {

      if (Array.isArray(this.value)) {
        this.$emit('input', [...this.value, ...draggedFiles])
      } else if (this.value) {
        this.$emit('input', [this.value, ...draggedFiles])
      } else {
        this.$emit('input', [...draggedFiles])
      }

      // If single file only replace value with first dropped files
    } else {
      this.$emit('input', draggedFiles[0])
    }
  }

  private getFileUrl(file: File): string {
    return URL.createObjectURL(file)
  }

  private deleteFile(index: number): void {
    // If input accepts multiple we need to check if the input already has a array of files
    if (this.multiple) {
      // If value is already an array we need to splice the file from the array
      // and replace the value with the new array
      if (Array.isArray(this.value)) {
        const files = [...this.value]
        files.splice(index, 1)
        this.$emit('input', files)
        return
      }
    }
    // If not always make it empty
    this.$emit('input', undefined)
  }

}
