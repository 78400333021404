export class FeedbackRequest {
  public message: string = ''
  public rating: 1|2|3|4|5|null = null
  public media_type?: 'rating' | 'support' | 'problem' | null = null
  public files: File[] = []

  private get isValid(): boolean {
    let valid = true
    valid = this.message.length > 0
    if (this.media_type === 'rating') valid = !!this.rating && [1, 2, 3, 4, 5].includes(this.rating)
    return valid
  }

  public requestBody(): FormData {
    const formData = new FormData()
    formData.append('message', this.message)
    formData.append('media_type', this.media_type ?? 'rating')

    if (this.rating) {
      formData.append('rating', this.rating.toString())
    }
    if (this.media_type === 'problem' || this.media_type === 'support') {
      for (const file of this.files) {
        formData.append('files[]', file)
      }
    }
    return formData
  }
}
