



















import { Vue, Component, Prop } from 'vue-property-decorator'

import { getHumanReadableDate } from '@/helpers/formatDate'
import Notification from '@/components/listItem/Notification.vue'

import { NotificationsController } from '@/controllers/notifications/NotificationsController'

@Component({
  components: {
    Notification
  }
})
export default class NotificationList extends Vue {

  @Prop() private notifications!: NotificationsController

  public formattedCreatedAt(date: string): string {
    const dateParts = date.split('-')

    return getHumanReadableDate(new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0]))
  }

}
